import React from "react";
import "../../App.css"; // Assuming you'll add the CSS styles here
import StartButton from "../StartButton";

const StartPage = () => {
  return (
    <div className="container">
      <h1 style={{marginTop:"100px",marginBottom:"100px"}}>Guess The Spy</h1>
      <img width={"100px"} src="https://cdn-icons-png.flaticon.com/512/3043/3043797.png"/>
      <StartButton/>
      <h3 style={{margin:"10px"}}>Made by Chris.</h3>
    </div>
  );
};

export default StartPage;
