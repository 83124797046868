import React from "react";
import "../App.css"; // Assuming you'll add the CSS styles here
import { useNavigate } from "react-router-dom";

const StartButton = () => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate("/setup")
    }
  return (
    <div className="container">
      <button className="start-button" onClick={handleClick}>Start</button>
    </div>
  );
};

export default StartButton;
