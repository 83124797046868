import React, { useState, useContext } from "react";
import { NameContext } from "../../context";
import { useNavigate } from "react-router-dom";


const Setup = () => {
  const { names, handleInputChange, addNameField, removeNameField } = useContext(NameContext);


  // Function to submit the form (for demo purposes)
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted names:", names);
    // You can send the names to the server or handle them as needed
  };

  const handleClick = () => {
        navigate("/game")
    }

  const navigate = useNavigate()

  return (
    <form onSubmit={handleSubmit}>
      <h2>Enter Names</h2>
      {names.map((name, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          <input
            type="text"
            value={name}
            onChange={(e) => handleInputChange(index, e)}
            placeholder={`Name ${index + 1}`}
            style={{ margin: "10px", padding: "8px", width: "200px" }}
          />
          <button 
            type="button" 
            onClick={() => removeNameField(index)} 
            style={{ padding: "5px", backgroundColor: "#ff4d4d", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }}>
            X
          </button>
        </div>
      ))}
      <button type="button" onClick={addNameField} style={{ margin: "10px", padding: "5px" }}>
        Add Another Name
      </button>
      <button type="submit" onClick={handleClick} style={{ margin: "10px", padding: "5px" }}>
        Start Game
      </button>
    </form>
  );
};

export default Setup;
