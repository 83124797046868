import React, { createContext, useState } from "react";

// Create the context
export const NameContext = createContext();

export const NameProvider = ({ children }) => {
  const [names, setNames] = useState([""]);

  const handleInputChange = (index, event) => {
    const newNames = [...names];
    newNames[index] = event.target.value;
    setNames(newNames);
  };

  const addNameField = () => {
    setNames([...names, ""]);
  };

  const removeNameField = (index) => {
    const newNames = names.filter((_, i) => i !== index);
    setNames(newNames);
  };

  return (
    <NameContext.Provider
      value={{ names, handleInputChange, addNameField, removeNameField }}
    >
      {children}
    </NameContext.Provider>
  );
};
