import React, { useState, useEffect, useContext } from "react";
import { NameContext } from "../../context";
import { useNavigate } from "react-router-dom";

const Game = () => {
  const { names } = useContext(NameContext);
  const [currentIndex, setCurrentIndex] = useState(0); // Track which name to display
  const [spyIndex, setSpyIndex] = useState(null); // Track the spy's index
  const [revealed, setRevealed] = useState(false); // Control when to reveal if the person is a spy
  const [sharedLocation, setSharedLocation] = useState(null); // Store the shared location for non-spies
  const [startPlayer, setStartPlayer] = useState(null); // Random starting player

  // List of possible locations for non-spy players
  const locations = [
    "Airport", "Amusement Park", "Art Gallery", "Bakery", "Bank", "Bar", "Beach", "Castle", "Circus Tent", "Cruise Ship",
    "Desert", "Factory", "Farm", "Football Stadium", "Forest", "Gas Station", "Hotel", "Hospital", "Library", "Mall",
    "Mountain Cabin", "Museum", "Nightclub", "Office Building", "Police Station", "Post Office", "Restaurant", "School",
    "Space Station", "Subway Station", "Supermarket", "Theater", "Train Station", "Zoo", "Ski Resort", "University Campus",
    "Aquarium", "Cinema", "Military Base", "Conference Center", "Botanical Garden", "Beach House", "Café", "Fishing Dock",
    "Ice Cream Parlor", "Lighthouse", "National Park", "Planetarium", "Rooftop Bar", "Spa", "Waterfall", "Winery"
  ];

  // Randomly select the spy and starting player and shared location when the component mounts
  useEffect(() => {
    const randomSpyIndex = Math.floor(Math.random() * names.length);
    setSpyIndex(randomSpyIndex);

    const randomStartPlayer = Math.floor(Math.random() * names.length);
    setStartPlayer(randomStartPlayer);
    setCurrentIndex(randomStartPlayer); // Set the starting player

    const location = getRandomLocation(); // Get a random location for non-spies
    setSharedLocation(location);
  }, [names]);

  // Randomly pick a location for non-spy players
  const getRandomLocation = () => {
    const randomIndex = Math.floor(Math.random() * locations.length);
    return locations[randomIndex];
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  // Move to the next name in order, looping back if necessary
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % names.length); // Loop through the names list
    setRevealed(false); // Hide the next person's role initially
  };

  // Reveal if the current person is the spy and assign location if not
  const handleReveal = () => {
    setRevealed(true);
  };

  // Check if the current player is the last one in the cycle
  const isLastPlayer = () => {
    return (currentIndex + 1) % names.length === startPlayer;
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {startPlayer !== null && (
        <>
          <h2>{`It's ${names[currentIndex]}'s turn`}</h2>

          <div style={{ margin: "20px" }}></div>

          {/* Only show the reveal message after clicking the "Reveal" button */}
          {revealed ? (
            currentIndex === spyIndex ? (
              <h3>You are the Spy!</h3>
            ) : (
              <h3>Location: {sharedLocation}</h3>
            )
          ) : (
            <button
              onClick={handleReveal}
              style={{ margin: "20px", padding: "10px", fontSize: "16px" }}
            >
              Reveal Role
            </button>
          )}

          <div style={{ margin: "20px" }}></div>

          {/* Only show "Next" button to move to the next player, if it's not the last player */}
          {revealed && !isLastPlayer() && (
            <button
              onClick={handleNext}
              style={{ padding: "10px", fontSize: "16px" }}
            >
              Next Player
            </button>
          )}

          {/* If all names have been shown, display a message to end the game */}
          {revealed && isLastPlayer() && (
            <div>
              <h3>All players have seen their roles. Begin!</h3>
              <button
                className="close-button"
                onClick={handleClick}
                style={{ margin: "20px", padding: "20px" }}
              >
                Close
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Game;
