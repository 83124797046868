import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StartPage from "./components/pages/StartPage";
import Setup from "./components/pages/Setup";
import { NameProvider } from "./context";
import Game from "./components/pages/Game";

const App = () => {
  return (
    <NameProvider>
    <Router>
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/game" element={<Game />} />

      </Routes>
    </Router>
    </NameProvider>
  );
};

export default App;
